import { useMemo } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import type { PageProps } from 'gatsby'
import { Link } from 'gatsby'

type Props = PageProps

const useErrorState = (location: Location) =>
  useMemo(() => {
    const params = new URLSearchParams(location.search)
    const fromUrl = decodeURI(params.get('from') ?? location.pathname)

    return {
      fromUrl,
    }
  }, [location.pathname, location.search])

function Page({ location }: Props) {
  const { fromUrl } = useErrorState(location)

  return (
    <>
      <GatsbySeo noindex nofollow />

      <h1>Not Found: 404</h1>
      <div>
        Lo sentimos no pudimos encontrar esa página. <br />
        Probá nuevamente realizando una búsqueda desde nuestra página de{' '}
        <Link to="/">inicio</Link>
      </div>
    </>
  )
}

export default Page

export { Head } from 'src/components/common/Head'
